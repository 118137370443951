/**
 * This file is used to configure the app
 *
 * If you have the "Cannot find name 'defineAppConfig'.ts(2304)" error
 * update the root tsconfig.json file to include the following:
 *
 *  "extends": "./.app/.nuxt/tsconfig.json"
 *
 */

import { configure } from 'vee-validate'

// Only validate inputs on blur
configure({
  validateOnBlur: true,
  validateOnChange: false,
  validateOnModelUpdate: false,
  validateOnInput: false,
})

export default defineAppConfig({
  app: {
    allowInactiveUsers: false,
  },
  nui: {
    defaultShapes: {
      input: 'rounded',
      button: 'rounded',
    },
  },
  tairo: {
    sidebar: {
      navigation: {
        enabled: true,
        startOpen: true,
        logo: {
          component: 'TairoLogo',
          resolve: true,
          props: { class: 'text-primary-600 h-10' },
        },
        items: [
          {
            title: 'Dashboard',
            icon: { name: 'ph:sidebar-duotone', class: 'w-5 h-5' },
            subsidebar: { component: 'SubsidebarDashboard' },
            activePath: '/dashboard',
          },
          {
            title: 'Support',
            icon: {
              name: 'material-symbols:contact-support-outline',
              class: 'w-5 h-5',
            },
            subsidebar: { component: 'SubsidebarDashboard' },
            activePath: '/support',
          },
          {
            title: 'Customize',
            icon: { name: 'ph:drop-half-bottom-duotone', class: 'w-5 h-5' },
            click: () => {
              const isOpen = useState('switcher-open', () => false)
              isOpen.value = true
            },
            position: 'end',
          },
        ],
      },
      toolbar: {
        showNavBurger: true,
        tools: [
          {
            component: 'ThemeToggle',
            props: {
              disableTransitions: true,
            },
          },
          {
            component: 'ToolbarLanguage',
          },
          {
            component: 'ToolbarNotifications',
          },
          {
            component: 'ToolbarActivity',
          },
          {
            component: 'ToolbarAccountMenu',
          },
        ],
      },
    },
    topnav: {
      navigation: {
        enabled: true,
        logo: {
          component: 'TairoLogo',
          resolve: true,
          props: { class: 'text-primary-600 h-10 w-10' },
        },
        // header: {
        //   // Stagedoos like organization select
        //   component: 'TopnavWorkspaceDropdown',
        // },
        items: [
          {
            name: 'Dashboard',
            icon: { name: 'ph:gauge-duotone', class: 'w-6 h-6' },
            activePath: '/',
            to: '/dashboard',
          },
          {
            name: 'Vacatures',
            icon: { name: 'ph:suitcase-duotone', class: 'w-6 h-6' },
            activePath: '/vacancies',
            to: '/vacancies',
          },
          {
            name: 'Vacaturebank',
            icon: { name: 'ph:suitcase-duotone', class: 'w-6 h-6' },
            activePath: '/vacancies/search',
            to: '/vacancies/search',
          },
          {
            name: 'Organisaties',
            icon: { name: 'ph:users-duotone', class: 'w-6 h-6' },
            activePath: '/organizations',
            to: '/organizations',
          },
          {
            name: 'Gebruikers',
            icon: { name: 'ph:users-duotone', class: 'w-6 h-6' },
            activePath: '/users',
            to: '/users',
          },
          {
            name: 'Aanvragen',
            icon: { name: 'ph:user-focus', class: 'w-6 h-6' },
            activePath: '/applications',
            to: '/applications',
          },
          {
            name: 'Content',
            activePath: '/content',
            to: '/content',
          },
          {
            name: 'Instellingen',
            activePath: '/settings',
            to: '/settings',
          },
          {
            name: 'Conversaties',
            activePath: '/conversations',
            to: '/conversations',
          },
        ],
      },
      circularMenu: {
        enabled: false,
        tools: [],
      },
      toolbar: {
        enabled: true,
        showTitle: false,
        tools: [
          {
            component: 'ThemeToggle',
            props: {
              disableTransitions: true,
            },
          },
          {
            component: 'ToolbarLanguage',
          },
          {
            component: 'ToolbarNotifications',
          },
          {
            component: 'ToolbarActivity',
          },
          {
            component: 'ToolbarAccountMenu',
            props: {
              horizontal: true,
            },
          },
        ],
      },
    },
    panels: [
      {
        name: 'language',
        position: 'right',
        component: 'PanelLanguage',
      },
      {
        name: 'activity',
        position: 'right',
        component: 'PanelActivity',
      },
    ],
    error: {
      logo: {
        component: 'img',
        props: {
          src: '/img/illustrations/system/404-1.svg',
          class: 'relative z-20 w-full max-w-lg mx-auto',
        },
      },
    },
  },
})
